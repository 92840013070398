import React from "react"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from "@mui/material";

const BlogPostContainer = ({children}) => {

  return (
    <>
      <div style={{width: "100%", textAlign: "left"}}>
        <Button variant="text" startIcon={<ArrowBackIcon />} style={{color: "#f8a418"}} href="/blog">
          Back To Blog
        </Button>
      </div>
      <div style={{width: "100%", textAlign: "justify"}}>
      {children}
      </div>
    </>
  )
}

export default BlogPostContainer