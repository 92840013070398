import React from "react";
import {
  styled,
  Container,
} from "@mui/material";

const IconWrapper = styled(Container)(
  () => `
  .blob {
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(87, 201, 34, 1);
	margin: 5px;
	height: 10px;
	width: 10px;
    padding: 0;
	transform: scale(1);
}
  .blob.up {
	background: rgba(87, 201, 34, 1);
	box-shadow: 0 0 0 0 rgba(87, 201, 34, 1);
	animation: pulse-up 4s infinite;
}

@keyframes pulse-up {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(87, 201, 34, 1);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(87, 201, 34, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(87, 201, 34, 0);
	}
}

.blob.down {
	background: rgba(255, 24, 66, 1);
	box-shadow: 0 0 0 0 rgba(255, 24, 66, 1);
	animation: pulse-down 4s infinite;
}

@keyframes pulse-down {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 24, 66, 1)};
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 24, 66, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 24, 66, 0);
	}
}
.blob.paused {
	background: #FFA319;
	box-shadow: 0 0 0 0 #FFA319;
}
`
);

const StatusIcon = ({pixelSize}) => {
  return (
      <IconWrapper
        style={{ margin: 0, padding: 0 }}
      >
        <div
          className="blob up"
          style={
            pixelSize && {
              width: pixelSize + "px",
              height: pixelSize + "px",
            }
          }
        ></div>
      </IconWrapper>
  );
};

export default StatusIcon;
