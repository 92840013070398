import { LoadingButton } from "@mui/lab";
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Switch
} from "@mui/material";
import React from "react";
import { Check } from "@mui/icons-material";



function numberToPrice(value) {
  return (
    "£" +
    (Number(value / 100) || 0)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}

const SubscriptionOption = (props) => {

  return (
    <Card style={{height: "100%", paddingBottom: 25, marginLeft: 4, marginRight: 4}}>
      <Typography
        component="h2"
        style={{ fontSize: 20, fontWeight: "bold", marginTop: 15, color: "#f8a418" }}
      >
        {props.name}
      </Typography>

      <Typography component="h2" style={{ fontSize: 20, marginTop: 15 }}>
        {props.showMonth
          ? numberToPrice(props.monthPriceInPence)
          : numberToPrice(props.yearPriceInPence)}
        <b>{props.showMonth ? " / month" : " / year"}</b>
      </Typography>
      <Typography
        component="h5"
        style={{ fontSize: 13, color: "grey", minHeight: 20 }}
      >
        {!props.showMonth && props.yearSavingString}
      </Typography>
      <Box>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginTop: 20, fontSize: 18 }}
          >
            {props.showMonth ? "Pay Monthly" : "Pay Annually" }
          </Typography>
          <Switch
            size="medium"
            checked={!props.showMonth}
            onChange={(_, checked) => props.setShowMonth(!checked)}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#f8a418"
              },
              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                backgroundColor: "#f8a418"
              }
            }}
          />
        </Box>
      <LoadingButton
        variant="contained"
        style={{ marginTop: 20, width: "90%", backgroundColor: "#f8a418", fontSize: 15, marginBottom: 20 }}
        onClick={() => {
          window.location.href = "https://app.statushive.net/signup";
        }}
      >
        Sign Up
      </LoadingButton>

      <List>
        {props.features.map((x) => (
          <ListItem key={x} style={{marginBottom: 0, paddingBottom: 0}}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <Check  style={{fontSize: "25px"}} htmlColor="#57CA22"/>
            </ListItemIcon>
            <ListItemText primary={<Typography style={{fontSize: 15}}>{x}</Typography>}/>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default SubscriptionOption;
