import React, {useEffect} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';

const Home = () => {

  useEffect(() => {
    document.title = 'Create Beautiful Status Pages - Status Hive';
  }, []);

  return (
    <>
      <Hero />
      <FeaturesTiles  />
      <FeaturesSplit />
      <Cta split />
    </>
  );
}

export default Home;