import React, { useState } from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import SubscriptionOption from "./SubscriptionOption";

const Prices = [
  {
    name: "Free",
    monthPriceInPence: 0,
    yearPriceInPence: 0,
    features: ["Unlimited Status Pages", "3 Status Checks", "Unlimited Team Members", "Free SSL Certificate"],
  },
  {
    name: "Advanced",
    monthId: "price_1M5UPPKud8Rb0X85rs0Kh6UU",
    monthPriceInPence: 1500,
    yearId: "price_1M5UPPKud8Rb0X85ceXaKtS9",
    yearPriceInPence: 15000,
    yearSavingString: "Get 2 months free!",
    features: [
      "Status Pages on Custom Domain",
      "10 Status Checks",
      "Unlimited Team Members",
      "Unlimited Subscriptions",
      "Free SSL Certificate"
    ],
  },
  {
    name: "Ultimate",
    monthId: "price_1M6rQvKud8Rb0X85mPsNE8r6",
    monthPriceInPence: 5000,
    yearId: "price_1M6rQvKud8Rb0X85AaAwPW3C",
    yearPriceInPence: 50000,
    yearSavingString: "Get 2 months free!",
    features: [
      "Status Pages on Custom Domains",
      "75 Status Checks",
      "Unlimited Team Members",
      "Unlimited Subscriptions",
      "Free SSL Certificate"
    ],
  },
];

const Pricing = () => {
  const [useYearlyPricing, setUseYearlyPricing] = useState(true);
  return (
    <Box style={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
      }}>
        
        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            mt="0px"
            style={{ width: "100%" }}
          >
            {Prices.map((x) => (
              <Grid item xs={12} md={4} sm={6} key={"prices " + x.name} mt={2} >
                <SubscriptionOption {...x} showMonth={!useYearlyPricing} setShowMonth={x => setUseYearlyPricing(!x)} />
              </Grid>
            ))}
          </Grid>
        </Box>
    </Box>
  );
};

export default Pricing;
