import React, {useEffect} from 'react';
import classNames from 'classnames';

// import sections
import BlogPost from "./../blog/BlogPost";
import FeaturesSplit from '../components/sections/FeaturesSplit';
import BlogAdvert from '../blog/BlogAdvert';


const outerClasses = classNames(
  'section center-content'
);

const innerClasses = classNames(
  'hero-inner section-inner'
);
const h1 = ({ children, ...props }) => <h1 {...props} style={{textAlign: "center", marginBottom: "20px"}}>{children}</h1>

const Why = () => {
  useEffect(() => {
    document.title = 'What is a Status Page and Why Do I Need One? - Status Hive';
  }, []);

  return (
    <section className={outerClasses}>
        <div className="container-sm">
            <div className={innerClasses} style={{padding: 0, textAlign: "justify"}}>
              <BlogPost url="what-and-why-status-page" hideAdvert markdownOptions={{overrides: {
                  h1: {
                      component: h1,
                  },
                }}} />
                <div style={{textAlign: "left"}}>
                <FeaturesSplit hideHeader />
                </div>
                <BlogAdvert />
            </div>
        </div>
    </section>
  );
}

export default Why;