import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Pricing from '../layout/partials/Pricing';
import SectionHeader from './partials/SectionHeader';
import { useHistory } from 'react-router-dom'

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const sectionHeader = {
  title: 'Pricing',
  paragraph: ''
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );



  const history = useHistory()
    useEffect(() => {
        history.listen((location) => {
          if (location.hash === "#pricing")
            document.getElementById("pricing").scrollIntoView()
        })
    }, [history])

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="pricing">
       <SectionHeader data={sectionHeader} showButton={false} className="center-content" />
        <Pricing />
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;