import React from 'react';
import BlogPost from "./BlogPost";
import { Card } from '@mui/material';
import classNames from 'classnames';
import BlogPostContainer from './BlogPostContainer';

const blogPostList = [
    "status-page-benefits",
    "brand-colours-are-important",
    "mobile-friendly-status-page",
    "uptime-monitoring-benefits",
    "status-page-benefits-for-customer-satisfaction",
];

const outerClasses = classNames(
    'section center-content'
  );

  const innerClasses = classNames(
    'hero-inner section-inner'
  );

const p = ({ children, ...props }) => {
    // Images were getting wrapped in <p> elements for some reason, meaning that their indentation was off. This stops that
    if (children.length > 0 && (typeof children[0] === 'string' || children[0] instanceof String)){
        return <p {...props}>{children}</p>;
    }

    return children;
}
const h1 = ({ children, ...props }) => <h1 {...props} style={{textAlign: "center", marginBottom: "20px"}}>{children}</h1>

const BlogHome = () => {
    const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    return (
    <section className={outerClasses}>
        <div className="container-sm">
            <div className={innerClasses} style={{padding: 0}}>
                {
                    url !== "" && url !== "blog" ?
                        (<BlogPostContainer>
                            <BlogPost url={url}/>
                        </BlogPostContainer>) :
                        (<div id="blog">
                            <h2 className={
                                classNames(
                                'mt-0',
                                'mb-0'
                                )}
                                style={{marginBottom: "100px"}}>Status Hive Blog</h2>
                            {blogPostList.map(x => (
                                <Card
                                    key={x}
                                    onClick={() => {window.location.href = "/blog/" + x;}}
                                    style={{marginBottom: "40px", textAlign: "justify", paddingTop: "50px", lineHeight: "1.6"}}>
                                    <div>
                                        <BlogPost url={x} markdownOptions={{overrides: {
                                            h1: {
                                                component: h1,
                                            },
                                            p: {
                                                component: p
                                            }
                                            }}}/>
                                    </div>
                                </Card>))}
                        </div>)
                }
        
             </div>
        </div>
    </section>
    )
}

export default BlogHome;