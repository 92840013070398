import React, {useState, useLayoutEffect} from 'react';
import { Card } from '@mui/material';
import classNames from 'classnames';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Logo from '../components/layout/partials/Logo';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

const BlogAdvert = () => {
    const [width, ] = useWindowSize();
    return (
        <Card
            onClick={() => {window.location.href = "/";}}
            style={{display: "flex", flexDirection: "row", padding: "20px", marginTop: "50px", gap: width < 700 ? 0 : 40, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)", maxHeight: "400px"}}>
                <img
                    key="image"
                    id="advertImage"
                    className="has-shadow"
                    src={require('./../assets/images/examplesites/statushivesmall.png')}
                    alt={"Status Page Example"}
                    style={{ flex:  width < 700 ? 0 : 1, width: width < 700 ? 0 : "300px", height: width < 850 ? "300px" : undefined, marginLeft: "auto", marginRight: "auto", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} />
            <div style={{flex: 1, height: "100%", marginTop: "40px", marginBottom: "auto"}}>
                
                <Logo />
                <h4 
                    className={
                        classNames(
                        'mt-0',
                        'mb-0'
                    )}
                    style={{marginTop: "40px", textAlign: "center"}}>
                    Create a status page as <span className="text-color-primary">unique</span>
                    {" "}as your brand with <span className="text-color-primary">Status Hive</span>
                </h4>
                <ButtonGroup style={{marginTop: "40px"}}>
                    <Button tag="a" color="primary" wideMobile href="/">
                        Get Started For Free!
                    </Button>
                </ButtonGroup>
            </div>
        </Card>
    )
}

export default BlogAdvert;