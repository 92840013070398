import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  hideHeader,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Feature Deep Dive',
    paragraph: 'Have a look at the key features which makes Status Hive ideal to serve your status page',
  };

  return (
    <section
      {...props}
      className={outerClasses}
      style={{paddingTop: hideHeader === true ? 0 : undefined, marginBottom: "100px"}}
    >
      <div className="container">
        <div className={innerClasses}>
          {hideHeader !== true && <SectionHeader data={sectionHeader} className="center-content" showButton={false} />}
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Customisable Status Pages
                  </div>
                <h3 className="mt-0 mb-12">
                  Easy To Setup, Highly Customisable
                  </h3>
                <p className="m-0">
                  Create beautiful status pages that reflect your brand in minutes. With Status Hive's custom status page editor, you can create a unique status page easily, without touching a single line of code.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features/customisableAdvanced.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Uptime Monitoring
                  </div>
                <h3 className="mt-0 mb-12">
                  Uptime Monitor And Status Page In One Place
                  </h3>
                <p className="m-0">
                  Status Hive performs ping and regex testing in house, meaning that there's no need to manage different service providers. Status Hive's uptime monitoring notifies you of any downtime while updating the status page, meaning that you always know the status of your site. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features/tests.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Status Events
                  </div>
                <h3 className="mt-0 mb-12">
                  Keep Everyone In The Loop
                  </h3>
                <p className="m-0">
                  Reduce the number of support requests that you get sent in the event of downtime. Creating an event displays an update on your status page and notifies all of your subscribers.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features/events.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;