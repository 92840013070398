import React from 'react';
import TermsContainer from '../components/sections/legal/TermsContainer';

const Legal = () => {
  const terms = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  
  return (
    <div style={{marginTop: "100px"}}>
      <TermsContainer termsType={terms}/>
    </div>
  );
}

export default Legal;