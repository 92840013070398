import React, { useState, useEffect } from "react"
import Markdown from "markdown-to-jsx"
import BlogAdvert from "./BlogAdvert"

const BlogPost = ({url, markdownOptions, hideAdvert}) => {
  const [postContent, setPostContent] = useState('')

  useEffect(() => {
    import('./content/' + url + '.md')
      .then(res =>
        fetch(res.default)
          .then(response => response.text())
          .then(response => setPostContent(response))
          .catch(() => {window.location.href = "/blog"})
      )
      .catch(() => {
        window.location.href = "/blog"
      })
  }, [url])

  return (
    <>
        <Markdown options={markdownOptions}>
            {postContent}
        </Markdown>
        {hideAdvert !== true && postContent !== '' && <BlogAdvert />}
    </>
  )
}

export default BlogPost