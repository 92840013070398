import React from "react";
import { Container, Typography } from "@mui/material";

const TermsSelector = (props) => {
  return (
    <Container style={{ marginTop: "100px"}}>
      <Typography onClick={() => {window.location.href = "/legal/cookie"}} style={{cursor: "pointer", userSelect: "none", margin: 0, fontSize: "21px", marginTop: "10px", width: "100%", textAlign: "left", marginLeft: "10%", fontWeight: props.terms === "cookie" || props.terms === "legal" || props.terms.length === 0 ? "bold" : "normal"}}>Cookie Policy</Typography>
      <Typography onClick={() => {window.location.href = "/legal/privacy"}} style={{cursor: "pointer", userSelect: "none", margin: 0, fontSize: "21px", marginTop: "10px", width: "100%", textAlign: "left", marginLeft: "10%", fontWeight: props.terms === "privacy" ? "bold" : "normal"}}>Privacy Policy</Typography>
      <Typography onClick={() => {window.location.href = "/legal/gdpr"}} style={{cursor: "pointer", userSelect: "none", margin: 0, fontSize: "21px", marginTop: "10px", width: "100%", textAlign: "left", marginLeft: "10%", fontWeight: props.terms === "gdpr" ? "bold" : "normal"}}>GDPR</Typography>
      <Typography onClick={() => {window.location.href = "/legal/refunds"}} style={{cursor: "pointer", userSelect: "none", margin: 0, fontSize: "21px", marginTop: "10px", width: "100%", textAlign: "left", marginLeft: "10%", fontWeight: props.terms === "refunds" ? "bold" : "normal"}}>Refunds</Typography>
      <Typography onClick={() => {window.location.href = "/legal/terms"}} style={{cursor: "pointer", userSelect: "none", margin: 0, fontSize: "21px", marginTop: "10px", width: "100%", textAlign: "left", marginLeft: "10%", fontWeight: props.terms === "terms" ? "bold" : "normal"}}>Terms Of Service</Typography>
      <Typography onClick={() => {window.location.href = "/legal/copyright"}} style={{cursor: "pointer", userSelect: "none", margin: 0, fontSize: "21px", marginTop: "10px", width: "100%", textAlign: "left", marginLeft: "10%", fontWeight: props.terms === "copyright" ? "bold" : "normal"}}>Copyright Policy</Typography>
      <Typography onClick={() => {window.location.href = "/legal/cancellation"}} style={{cursor: "pointer", userSelect: "none", margin: 0, fontSize: "21px", marginTop: "10px", width: "100%", textAlign: "left", marginLeft: "10%", fontWeight: props.terms === "cancellation" ? "bold" : "normal"}}>Cancellation Policy</Typography>
    </Container>
  );
};

export default TermsSelector;
