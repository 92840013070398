import React, {useState} from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StatusIcon from '../elements/StatusIcon';
import { Box, Typography } from '@mui/material';
import StatusPageExample from '../elements/StatusPageExample';
import Logo from "../../assets/images/featureicons/capterra.png"
import StarIcon from '@mui/icons-material/Star';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const [hoverOnOnline, setHoverOnOnline] = useState(false);
  const [activeSite, setActiveSite] = useState(2);

  return (
    <section
      {...props}
      className={outerClasses}
      style={{marginBottom: "100px"}}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{marginTop: "10vh"}}>
              Create a status page as <span className="text-color-primary">unique</span> as your brand
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Easily communicate your service&apos;s status and monitor your uptime for free with Status Hive.
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://app.statushive.net/signup" style={{marginBottom: 0}}>
                      Get Started For Free!
                    </Button>
                      <Button tag="a" color="success" wideMobile href="https://status.statushive.net" onMouseOver={() => setHoverOnOnline(true)} onMouseOut={() => setHoverOnOnline(false)}style={{color: "rgba(87, 201, 34, 1)", paddingLeft: "50%", paddingRight: "50%", marginTop: 0}}>
                        <Box style={{marginTop: 0, marginRight: 5}}>
                          <StatusIcon pixelSize={15} />
                          </Box>
                          We're Online
                          <OpenInNewIcon style={{marginLeft: 10, marginTop: 5, fontSize: "15px", opacity: hoverOnOnline ? "100%" : "0%"}}/>
                      </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800" >
            <Box sx={{
              display: "flex",
              flexDirection: {md: "row", xs: "column"},
              gap: 1,
              marginLeft: {md: 0, xs: "auto"},
              marginRight: {md: 0, xs: "auto"},
              width: {md: "100%", xs: "max-content"}}}>
              <StatusPageExample
                siteName="View Code Poster's Status Page"
                siteImage={require('./../../assets/images/siteexamples/codeposter.png')}
                siteUrl="https://status.codeposter.net"
                activeSite={activeSite}
                setActiveSite={setActiveSite}
                index={1}
              />
              <StatusPageExample
                siteName="View Founder Drip's Status Page"
                siteImage={require('./../../assets/images/siteexamples/founderdrip.png')}
                siteUrl="https://founderdrip.status.statushive.net"
                activeSite={activeSite}
                setActiveSite={setActiveSite}
                index={2}
              />
              <StatusPageExample
                siteName="View Mr Scraper's Status Page"
                siteImage={require('./../../assets/images/siteexamples/mrscraper.png')}
                siteUrl="https://mrscraper.status.statushive.net"
                activeSite={activeSite}
                setActiveSite={setActiveSite}
                index={3}
              />
            </Box>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800" style={{marginTop: "70px"}} >
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.capterra.co.uk/software/1041940/status-hive">
                <Box style={{ border: "2px solid #9CA9B3", borderRadius: 10, verticalAlign: "middle", padding: "10px", paddingTop: "3px", transition: "background-color 200ms linear"}} sx={{"&:hover": {backgroundColor: "#fefefe"}}}>
                  <Typography style={{fontSize: "13px", color: "#a1abb3", paddingLeft: "65px", textAlign: "left"}}>Capterra</Typography>
                  <div style={{display: "flex", flexDirection: "row", marginTop: "-10px"}}>
                    <img src={Logo} alt="Capterra Logo" style={{maxWidth: "50px", marginRight: "10px", marginLeft: "0"}} />
                    <StarIcon style={{marginTop: "auto", marginBottom: "auto", color: "#ff9d28"}}/>
                    <StarIcon style={{marginTop: "auto", marginBottom: "auto", color: "#ff9d28"}}/>
                    <StarIcon style={{marginTop: "auto", marginBottom: "auto", color: "#ff9d28"}}/>
                    <StarIcon style={{marginTop: "auto", marginBottom: "auto", color: "#ff9d28"}}/>
                    <StarIcon style={{marginTop: "auto", marginBottom: "auto", color: "#ff9d28"}}/>
                  </div>
                </Box>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;