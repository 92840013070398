import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import Redirect from "./components/sections/Redirect"

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Why from './views/Why';
import Legal from './views/Legal';
import BlogHome from './blog/BlogHome';

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/why-status-page" component={Why} layout={LayoutDefault} />

          <AppRoute exact path="/legal" component={Legal} layout={LayoutDefault} />
          <AppRoute exact path="/legal/*" component={Legal} layout={LayoutDefault} />

          <AppRoute exact path="/blog" component={BlogHome} layout={LayoutDefault} />
          <AppRoute exact path="/blog/*" component={BlogHome} layout={LayoutDefault} />
          <AppRoute exact path="/*" component={Redirect} />
        </Switch>
      )} />
  );
}

export default App;