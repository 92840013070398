import React from "react";
import Markdown from "markdown-to-jsx";
import { Card, Container, Grid } from "@mui/material";
import { termsOfService } from "./TermsOfService";
import { cancellationPolicy } from "./CancellationPolicy";
import { copyright } from "./Copyright";
import { privacy } from "./PrivacyPolicy";
import { refunds } from "./Refunds";
import { gdpr } from "./GDPR";
import CookiePolicy from "./CookiePolicy";
import TermsSelector from "./TermsSelector";

const TermsContainer = (props) => {
  if (props.termsType === "cookie" || props.termsType === "legal" || props.termsType.length === 0) {
    return (
      <Card style={{ width: "90%", marginLeft: "5%", marginTop: 20, maxHeight: "auto !important" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch">
          <Grid item xs={12} sm={3} style={{margin: 0, padding: 0}}>
            <TermsSelector terms={props.termsType} />
          </Grid>
          <Grid item xs={12} sm={9} style={{}}>
            <Container style={{ padding: 0, margin: 40, width: "90%" }}>
              <CookiePolicy />
            </Container>
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card style={{ width: "90%", marginLeft: "5%", marginTop: 20 }}>
      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch">
          <Grid item xs={12} sm={3} style={{margin: 0, padding: 0}}>
            <TermsSelector terms={props.termsType} />
          </Grid>
          <Grid item xs={12} sm={9} style={{}}>
          <Container style={{ padding: 0, margin: 40, width: "90%" }}>
            <Markdown>
              {props.termsType === "terms"
                ? termsOfService
                : props.termsType === "cancellation"
                ? cancellationPolicy
                : props.termsType === "copyright"
                ? copyright
                : props.termsType === "privacy"
                ? privacy
                : props.termsType === "refunds"
                ? refunds
                : props.termsType === "gdpr"
                ? gdpr
                : ""}
            </Markdown>
          </Container>
          </Grid>
        </Grid>
    </Card>
  );
};

export default TermsContainer;
