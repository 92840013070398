import React, {useState, useLayoutEffect} from 'react';
import { Card, Tooltip } from '@mui/material';
import Image from '../elements/Image';

const StatusPageExample = ({
  siteName,
  siteImage,
  siteUrl,
  activeSite,
  setActiveSite,
  index,
  ...props
}) => {
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, ] = useWindowSize();

  return (
    <Tooltip title={siteName}>
        <Card {...props} onMouseOver={() => setActiveSite(index)} style={{flex: activeSite === index ? 2 : 1, transition: "all .4s linear", minHeight: "230px", maxHeight: "230px", cursor: "pointer", width: "max-content"}}>
            <a href={siteUrl} target="_blank" rel="noopener noreferrer">
            <Image
                src={siteImage}
                alt={siteName}
                width={width < 550 ? width - 40 : 500}
                height={width < 330 ? width - 20 : 500} />
            </a>
        </Card>
    </Tooltip>
  );
}

export default StatusPageExample;